
import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout';
import Header from '../components/header'


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {

  console.log(data)
  const {markdownRemark} = data;
  const {frontmatter} = markdownRemark;

  return (
  <Layout>
    <Header text={frontmatter.headerText} image={frontmatter.headerImage}/>
    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">{frontmatter.downloadTitle}</h2>
            <p  dangerouslySetInnerHTML={{ __html: frontmatter.downloadText }} />
            <div className="badges">
              <a className="badge-link" href={frontmatter.downloadLinkAndroid}>
                <img src="img/google-play-badge.svg" alt=""/>
              </a>
              <a className="badge-link" href={frontmatter.downloadLinkIos}>
                <img src="img/app-store-badge.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2  dangerouslySetInnerHTML={{ __html: frontmatter.slogan}}/>
        </div>
      </div>
      <div className="overlay"></div>
    </section>
  </Layout>
)}


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        headerText
        headerImage
        downloadTitle
        downloadText
        downloadLinkAndroid
        downloadLinkIos
        slogan
      }
    }
  }
`